import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { DrawerHeader } from 'common/components/navigatorComponents';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';
import { FORMAT_RATE } from 'common/util/commonUtil';
import CustomDivider from 'common/components/CustomDivider';

function NpiRateDrawer({ open, onClose, npiRates, billingDetails }) {
  const theme = useTheme();

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <DrawerHeader />
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </Box>
      <List sx={{ width: 300, paddingTop: '0', paddingBottom: '0' }}>
        <ListItem sx={{ paddingTop: '0', paddingBottom: '0' }}>
          <ListItemText
            primary={(<Typography variant="h2" color={theme.palette.darkest.main}>{billingDetails.networkLabel}</Typography>)}
            secondary={(
              <>
                <Typography variant="body1" color={theme.palette.darkest.main}>
                  {`${billingDetails.billingCodeType} ${billingDetails.billingCode}`}
                </Typography>
                <Typography variant="body1" color={theme.palette.darkest.main}>
                  {`NPI Count: ${npiRates.length}`}
                </Typography>
              </>
            )} />
        </ListItem>
        <CustomDivider />
        {npiRates.map((item) => (
          <React.Fragment key={item.npi}>
            <ListItem sx={{ paddingTop: '0', paddingBottom: '0' }}>
              <ListItemText
                primary={item.providerName}
                secondary={(
                  <>
                    <Typography component="span" variant="body1" color="text.primary">
                      {item.npi}
                    </Typography>
                    <br />
                    <Typography component="span" variant="body1" color="text.primary">
                      {FORMAT_RATE(item.rate)}
                    </Typography>
                  </>
                )}
              />
            </ListItem>
            <CustomDivider />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default NpiRateDrawer;
