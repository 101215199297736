import React from 'react';
import { Typography, Table, TableHead, TableRow } from '@mui/material';
import { EVALTYPE } from 'common/Constants';
import { NetworkSubColumnCell } from './PopulationStyles';

function Column({ columnName, width, align = 'center', sx = {} }) {
  return (
    <NetworkSubColumnCell width={width} align={align} padding="none" sx={sx}>
      <Typography variant="paraHeader">{columnName}</Typography>
    </NetworkSubColumnCell>
  );
}

function StudyTableColumnNames() {
  return (
    <>
      <Column width="20%" columnName="% Medicare" />
      <Column width="40%" columnName="MRF Rate" align="right" />
      <Column width="20%" columnName="NPI Count" align="right" sx={{ paddingLeft: '15px' }} />
      <Column width="20%" columnName="Data Usability" sx={{ paddingLeft: '15px' }} />
    </>
  );
}

const EvalTypeColumnNames = {
  [EVALTYPE.POPULATION]: () => (
    <>
      <Column width="25%" columnName="% Medicare" />
      <Column width="45%" columnName="NPI Count" />
      <Column width="30%" columnName="Data Usability" />
    </>
  ),
  [EVALTYPE.PROVIDER]: () => <StudyTableColumnNames />,
  [EVALTYPE.MARKET]: () => <StudyTableColumnNames />,
};

export default function NetworkResultsHeader({ evalType }) {
  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow>
          {EvalTypeColumnNames[evalType]()}
        </TableRow>
      </TableHead>
    </Table>
  );
}
