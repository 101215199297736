import React, { useEffect, useState } from 'react';
import { TableContainer, TableHead, Grid } from '@mui/material';
import { EVALTYPE, LIGHTER_GRAY_200, WHITE } from 'common/Constants';
import PopulationMSA from './PopulationMSA';
import { StudyResultsTable, TableCellNetworkStyledHeader, TableRowStyled, TypographyNetworkStyledName } from './PopulationStyles';
import HeaderGroup from './HeaderGroup';
import NetworkResultsHeader from './NetworkResultsHeader';
import MsaResultsHeader from './MsaResultsHeader';
import PopulationMSADropdwon from './PopulationMSADropdwon';

const headerName = {
  [EVALTYPE.POPULATION]: 'MSA',
  [EVALTYPE.PROVIDER]: 'Code Categories',
  [EVALTYPE.MARKET]: 'Code Categories',
};

export default function PopulationResultDetail({
  networkSummaries = [],
  msaResults = {},
  evalType,
  billingClass,
}) {
  const msaObjectResults = Object.keys(msaResults);
  const [filteredMsa, setFilteredMsa] = useState(msaObjectResults);

  useEffect(() => {
    setFilteredMsa(Object.keys(msaResults));
  }, [msaResults]);

  return (
    <>
      {evalType === EVALTYPE.POPULATION
        && (
          <PopulationMSADropdwon
            setFilteredMsa={setFilteredMsa}
            msaObjectResults={msaObjectResults}
            msaResults={msaResults} />
        )}
      <TableContainer sx={{
        maxHeight: (window.innerHeight - 400) > 300 ? (window.innerHeight - 400) : 300,
        minHeight: (window.innerHeight - 650),
        marginLeft: '16px',
        marginTop: '5px',
      }}>
        <StudyResultsTable stickyHeader aria-label="collapsible table" sx={{ tableLayout: 'fixed' }}>
          <HeaderGroup />
          <TableHead>
            <TableRowStyled>
              <TableCellNetworkStyledHeader sx={{ backgroundColor: `${WHITE} !important` }}>
                <Grid container>
                  <Grid item xs={12} sx={{ position: 'relative', top: '-9px' }}>
                    <TypographyNetworkStyledName variant="h6">
                      {headerName[evalType]}
                    </TypographyNetworkStyledName>
                  </Grid>
                  <Grid item xs={12}>
                    <MsaResultsHeader evalType={evalType} />
                  </Grid>
                </Grid>
              </TableCellNetworkStyledHeader>
              {networkSummaries.map((item, index) => (
                <TableCellNetworkStyledHeader
                  key={`${item.network.code}`}
                  sx={{ backgroundColor: index % 2 === 0 ? `${LIGHTER_GRAY_200} !important` : `${WHITE} !important` }}>
                  <TypographyNetworkStyledName variant="h6">{item.network.label}</TypographyNetworkStyledName>
                  <NetworkResultsHeader evalType={evalType} />
                </TableCellNetworkStyledHeader>
              ))}
            </TableRowStyled>
          </TableHead>
          {msaObjectResults.length ? filteredMsa
            .map((msa) => (
              <PopulationMSA
                key={JSON.parse(msa).name}
                msa={JSON.parse(msa)}
                msaPopulationResult={msaResults[msa]}
                networkSummaries={networkSummaries}
                evalType={evalType}
                billingClass={billingClass}
              />
            )) : null
          }
        </StudyResultsTable>
      </TableContainer>
    </>
  );
}
