import { Typography, TableRow, TableCell, Grid, Table } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LIGHTER_GRAY_300 } from 'common/Constants';

export const BorderTableCell = styled(TableCell)(() => ({
  paddingTop: '0px',
  paddingBottom: '0px',
  paddingLeft: '3px',
}));

export const PopStudyTableCell = styled(BorderTableCell)(() => ({
  borderBottom: 'none !important',
}));

export const NetworkSubColumnCell = styled(PopStudyTableCell)({
  paddingRight: 0,
});

export const TypographyStyledName = styled(Typography)(({ theme }) => ({
  color: theme.palette.darkest.main,
  fontSize: '0.8rem',
  paddingLeft: '40px',
}));

export const TableCellNetworkValues = styled(BorderTableCell)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.accent.shade200,
  },
  paddingRight: 0,
}));

export const TypographyNetworkStyledName = styled(Typography)(({ theme }) => ({
  color: theme.palette.darkest.main,
  justifyContent: 'center',
  margin: 'auto',
  textAlign: 'center',
  fontSize: '16px',
}));

export const TypographyMsaStyledName = styled(Typography)(({ theme }) => ({
  color: theme.palette.darkest.main,
  fontSize: '13px',
}));

export const TableCellNetworkStyledHeader = styled(BorderTableCell)(({ theme }) => ({
  backgroundColor: theme.palette.accent.shade300,
  paddingBottom: '5px',
  borderBottom: `2px solid ${LIGHTER_GRAY_300}`,
}));

export const TableRowStyled = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.white,
}));

export const GridMsaDropdownStyled = styled(Grid)(() => ({
  paddingBottom: '20px',
  paddingTop: '5px',
}));

export const StudyResultsTable = styled(Table)({
  '& tbody .MuiTypography-root': {
    fontSize: '0.875rem',
  },
});
