import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { SHOW_ALL_RESULT } from 'common/Constants';
import MultiSelect from 'common/components/MultiSelect';

export default function PopulationMSADropdwon({ setFilteredMsa, msaResults, msaObjectResults }) {
  const msaDropdownList = Object.keys(msaResults).map((msaRecord) => {
    const parsedMsa = JSON.parse(msaRecord);
    return {
      id: parsedMsa.msaNo,
      label: `${parsedMsa.name} ${parsedMsa.msaPopulation.toLocaleString('en-US')} ppl ${parsedMsa.populationPercent} %`,
      value: parsedMsa.name,
    };
  });

  const handleMsaOnChange = (selectedMsas) => {
    if (selectedMsas.some((msa) => msa.label === SHOW_ALL_RESULT)) {
      setFilteredMsa(msaObjectResults);
    } else {
      setFilteredMsa(msaObjectResults.filter((msa) =>
        selectedMsas.some((selectedMsa) => JSON.parse(msa).name === selectedMsa.value)));
    }
  };

  const [selectedOptions, setSelectedOptions] = useState(msaDropdownList);

  const setMsaOptions = (value) => {
    setSelectedOptions(value);
    handleMsaOnChange(value);
  };

  const handleToggleOption = (value) => {
    setMsaOptions(value);
  };
  const handleClearOptions = () => {
    setMsaOptions([]);
  };
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setMsaOptions(msaDropdownList);
    } else {
      handleClearOptions();
    }
  };
  return (
    <>
      <Grid container item xs={3}>
        <MultiSelect
          items={msaDropdownList}
          selectAllLabel="Select All"
          limitTags={3}
          onChange={(selectedMsas) => {
            handleMsaOnChange(selectedMsas);
          }}
          selectedOptions={selectedOptions}
          onToggleOption={handleToggleOption}
          onClearOptions={handleClearOptions}
          onSelectAll={handleSelectAll}
          allLabel="All MSA's"
          label={`Filtered MSA's: ${selectedOptions.length} of ${msaDropdownList.length}`}
        />
      </Grid>
      <Grid container item>
        <Typography>Only MSAs with available study data can be expanded.</Typography>
      </Grid>
    </>
  );
}
