import React from 'react';

export default function HeaderGroup() {
  return (
    <colgroup>
      <col width="22%" />
      <col width="19%" />
      <col width="20%" />
      <col width="19%" />
      <col width="20%" />
    </colgroup>
  );
}
