import React from 'react';
import { Table, TableBody, Grid } from '@mui/material';
import { capitalizePhrase } from 'common/util/commonUtil';
import { EVALTYPE } from 'common/Constants';
import NetworkResult from './NetworkResult';
import HeaderGroup from './HeaderGroup';
import {
  BorderTableCell,
  TableCellNetworkValues,
  TableRowStyled,
  TypographyStyledName,
  TypographyMsaStyledName,
} from './PopulationStyles';

export default function PopulationCategory({
  msa,
  categoryLevelPopulations,
  networkSummaries,
  evalType,
  billingClass,
}) {
  const { msaNo } = msa;

  const getCategoryDetails = (categoryLevel) => {
    let catergoryDetails;

    switch (evalType) {
      case EVALTYPE.POPULATION:
        catergoryDetails = (
          <BorderTableCell>
            <TypographyStyledName variant="h6">
              {categoryLevel.billingClass === 'professional'
                ? `${capitalizePhrase(categoryLevel.billingClass)}`
                : `${categoryLevel.category} ${capitalizePhrase(categoryLevel.billingClass)}`}
            </TypographyStyledName>
          </BorderTableCell>
        );
        break;
      default:
        catergoryDetails = (
          <BorderTableCell>
            <Grid item container sx={{ padding: '0px 16px 0px 25px' }}>
              <Grid item xs={9}>
                <TypographyMsaStyledName variant="h6">{`${categoryLevel.billingCodeType} ${categoryLevel.billingCode}`}</TypographyMsaStyledName>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: 'right !important' }}>
                {![null, undefined].includes(categoryLevel.weight) && `${categoryLevel.weight}%`}
              </Grid>
            </Grid>
          </BorderTableCell>
        );
    }
    return catergoryDetails;
  };

  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <HeaderGroup />
      <TableBody>
        {(categoryLevelPopulations).map((categoryLevel) => (
          <TableRowStyled
            key={evalType === EVALTYPE.POPULATION
              ? `${categoryLevel.category}-${categoryLevel.billingClass}`
              : categoryLevel.billingCode}
            sx={{ backgroundColor: (theme) => (theme.palette.background.white) }}>
            {getCategoryDetails(categoryLevel)}
            {(networkSummaries || []).map((item, idx) => (
              <TableCellNetworkValues
                idx={idx}
                key={`${msaNo} ${item.network.code}`}
              >
                <NetworkResult
                  networkResult={categoryLevel.studyResultNetwork[item.network.code]}
                  networkCode={item.network.code}
                  networkLabel={item.network.label}
                  categoryDetails={categoryLevel}
                  evalType={evalType}
                  billingClass={billingClass}
                />
              </TableCellNetworkValues>
            ))}
          </TableRowStyled>
        ))}
      </TableBody>
    </Table>
  );
}
