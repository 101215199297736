import React from 'react';
import { Typography, Table, TableHead, TableRow } from '@mui/material';
import { EVALTYPE } from 'common/Constants';
import { PopStudyTableCell } from './PopulationStyles';

function PopulationHeaderColumns() {
  return (
    <>
      <PopStudyTableCell width="24%" align="center">
        <Typography variant="paraHeader">Population Count</Typography>
      </PopStudyTableCell>
      <PopStudyTableCell width="26%" align="center">
        <Typography variant="paraHeader">Population %</Typography>
      </PopStudyTableCell>
    </>
  );
}

function StudyHeaderColumn() {
  return (
    <PopStudyTableCell width="50%">
      <Typography variant="paraHeader" sx={{ float: 'right !important' }}>Weight</Typography>
    </PopStudyTableCell>
  );
}

export default function MsaResultsHeader({ evalType }) {
  const evalTypeHeaders = {
    [EVALTYPE.POPULATION]: PopulationHeaderColumns,
    [EVALTYPE.PROVIDER]: StudyHeaderColumn,
    [EVALTYPE.MARKET]: StudyHeaderColumn,
  };
  const HeaderComponent = evalTypeHeaders[evalType];

  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow>
          <PopStudyTableCell width="50%" align="center">
            <Typography variant="paraHeader">Name</Typography>
          </PopStudyTableCell>
          <HeaderComponent />
        </TableRow>
      </TableHead>
    </Table>
  );
}
