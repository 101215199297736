import React, { useEffect, useState } from 'react';
import store from 'app/store';
import { getEvaluationResultDetails } from 'features/evaluation/state/service';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import DataUsabilityRatingLink from 'common/components/DataUsabilityRatingLink';
import { Grid } from '@mui/material';
import { EVALTYPE, NO_RESULT_TO_DISPLAY } from 'common/Constants';
import HeaderTabs from 'features/evaluation/result/HeaderTabs';
import NetworkSummaries from './NetworkSummaries';
import PopulationResultDetail from './PopulationResultDetail';

const ParagraphStyled = styled('div')(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: '500',
  paddingLeft: '20px',
}));

const evaluationResultForMSAs = {
  [EVALTYPE.POPULATION]: 'populationResultForMSAs',
  [EVALTYPE.PROVIDER]: 'studyResultDetails',
  [EVALTYPE.MARKET]: 'studyResultDetails',
};

export default function StudyResults({ evalId, evalType }) {
  const resultDetails = useSelector(
    (state) => {
      if (state.evaluation.resultDetails.studyResultDto?.evaluationId
        === Number(evalId)) {
        return state.evaluation.resultDetails.studyResultDto;
      }
      return undefined;
    },
  );
  const isResultLoading = useSelector((state) => state.evaluation.isResultLoading);
  const [selectedTab, setSelectedTab] = useState('institutional');

  useEffect(() => {
    store.dispatch(getEvaluationResultDetails(
      { evaluationId: evalId },
    ));
  }, [evalId]);

  const getSelectedTabData = () => resultDetails[evaluationResultForMSAs[evalType]]
    ?.[selectedTab]?.studyResultCategory;

  const getResultdata = () => {
    if ([EVALTYPE.PROVIDER, EVALTYPE.MARKET].includes(evalType)) {
      return getSelectedTabData();
    }
    return resultDetails[evaluationResultForMSAs[evalType]];
  };

  const isDataAvailble = () => resultDetails
    && resultDetails[evaluationResultForMSAs[evalType]]
    && Object.keys(resultDetails[evaluationResultForMSAs[evalType]]).length > 0
    && ((evalType === EVALTYPE.PROVIDER || evalType === EVALTYPE.MARKET)
      ? Object.keys(getSelectedTabData()).length > 0 : true);

  return (
    <>
      <NetworkSummaries summaries={resultDetails?.networkSummaries} />
      <Grid item xs={12} mt="-1rem" mb=".8rem">
        <DataUsabilityRatingLink />
      </Grid>
      {[EVALTYPE.PROVIDER, EVALTYPE.MARKET].includes(evalType)
        && <HeaderTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      }
      {isDataAvailble()
        ? (
          <PopulationResultDetail
            key={`${evalId}-${selectedTab}`}
            billingClass={selectedTab}
            networkSummaries={resultDetails.networkSummaries}
            msaResults={getResultdata()}
            evalType={evalType} />
        )
        : (
          !isResultLoading && (
            <ParagraphStyled>
              {NO_RESULT_TO_DISPLAY}
            </ParagraphStyled>
          )
        )}
    </>
  );
}
